import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import "./searchSection.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { moveToCompanyPage } from "../../actions/helper";

export const SearchSection = () => {
  const [query, setQuery] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typedPlaceholder, setTypedPlaceholder] = useState("");

  const [companyNameSuggestions, setCompanyNameSuggestions] = useState([]);

  const assigneeSuggestionsAPI =
    "https://eaf3yrusqj.execute-api.us-east-1.amazonaws.com/production/assignee-suggestions";

  const handleNameChange = (e) => {
    if (e.target.value.length > 0) {
      axios
        .get(assigneeSuggestionsAPI, {
          params: { assignee: e.target.value },
          headers: {
            "x-api-key":
              "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          },
        })
        .then((response) => {
          setCompanyNameSuggestions(response.data);
        });
    }
  };

  const handleSuggestionClick = (name) => {
    setCompanyNameSuggestions([]);
    moveToCompanyPage(navigate, name, "overview");
  };

  const placeholders = [
    "Google, LLC",
    "IBM",
    "Facebook, Inc",
    "Deepmind Technologies Ltd.",
    "Nvidia Corporation",
    "Tesla, Inc.",
    "Mastercard, Inc",
    "Moderna, Inc.",
  ];

  useEffect(() => {
    const placeholderText = placeholders[placeholderIndex];
    let currentPlaceholder = "";

    const interval = setInterval(() => {
      if (currentPlaceholder.length < placeholderText.length) {
        currentPlaceholder += placeholderText[currentPlaceholder.length];
        setTypedPlaceholder(currentPlaceholder);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % placeholders.length
          );
        }, 2000);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [placeholderIndex]);

  const navigate = useNavigate();

  return (
    <Container className="searchSection">
      {/* <Row>
        <Col
          xs="12"
          md="12"
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="parallaxClasses">
            <div className="searchBox2">
              <div className="searchDiv2 landingSearchDiv2">
                <p
                  className="landingPara2 brandName-para2"
                  style={{ color: "white", marginTop: "10px" }}
                >
                  Do Your Research
                </p>
                <br />
                <div className="landingBrandname2">
                  Discover companies, technologies
                  <br />
                  & innovators shaping
                  <br />
                  the future.
                </div>
              </div>
            </div>
          </div>

          <div
            className="searchBarWrapper"
            style={{ background: "#FAF1EC", marginBottom: "0px" }}
          >
            <input
              className="searchBar2"
              style={{ border: "none" }}
              placeholder={typedPlaceholder}
              onChange={handleNameChange}
            />
            <FaSearch
              className="searchIcon"
              style={{ top: "0px" }}
              onChange={handleNameChange}
            />
            {companyNameSuggestions.length > 0 && (
              <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    width: "50%",
                    marginTop: "5px",
                    position: "absolute",
                    zIndex: "9999",
                    maxHeight: "200px",
                    overflow: "auto",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {companyNameSuggestions.map((item, index) => (
                    <li
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleSuggestionClick(item)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#f9f9f9",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        marginBottom: "2px",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#ebebeb";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#f9f9f9";
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </h1>
            )}
          </div>
        </Col>
      </Row> */}

      <Row className="g-0">
        <Col
          xs="12"
          md="12"
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="parallaxClasses">
            <div className="searchBox2">
              <div className="searchDiv2 landingSearchDiv2">
                <p>Do Your Research</p>
                <p
                  className="landingPara2 brandName-para2"
                  style={{
                    color: "white",
                    marginTop: "15px",
                    fontSize: "2rem",
                    background:
                      "linear-gradient(to right, #020B26, #007FFF) text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bolder",
                  }}
                >
                  Unlock the Power of
                  <br />
                  Research Intelligence.
                </p>
                <br />
                <div className="landingBrandname2" style={{ color: "black" }}>
                Use DYR to discover organisations, technologies, and innovators shaping the future.
                </div>
              </div>
            </div>
          </div>

          <div className="searchBarWrapper" style={{ marginBottom: "0px" }}>
            <input
              className="searchBar2"
              placeholder={typedPlaceholder}
              onChange={handleNameChange}
            />
            <FaSearch
              className="searchIcon"
              style={{ top: "0px" }}
              onChange={handleNameChange}
            />
            {companyNameSuggestions.length > 0 && (
              <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    width: "50%",
                    marginTop: "5px",
                    position: "absolute",
                    zIndex: "9999",
                    maxHeight: "200px",
                    overflow: "auto",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {companyNameSuggestions.map((item, index) => (
                    <li
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleSuggestionClick(item)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#f9f9f9",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        marginBottom: "2px",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#ebebeb";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#f9f9f9";
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </h1>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
