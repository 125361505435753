import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Popover } from "reactstrap";
import {
  base64Decode,
  moveToTechnologyPage,
  removeDuplicateTechnologies,
  toTitleCase,
} from "../../actions/helper";
import Skeleton from "react-loading-skeleton";
import "../../components/Tables/cpcTable.css"

export const ReportCPC = (props) => {
  const [popoverContent, setPopoverContent] = useState({ title: "", body: "" });
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const sectionId = "TableRow";

  const [technologySummary,setTechnologySummary] = useState()

  const technologyAnalysisURL = "https://eaf3yrusqj.execute-api.us-east-1.amazonaws.com/production/technology-analysis"

  const generateTechnologySummary = (technology) => {
    return new Promise((resolve,reject)=>{
      axios.get(technologyAnalysisURL,{params:{cpc:technology.cpc},headers:{"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}}).then((response)=>{
        let totalApplications = response.data["total_applications"]
        let topAssignee = response.data["assignee_analysis"]["top_assignees"][0].assignee
        let topCountry = response.data["country_analysis"]["top_countries"][0].country
        let topYear = response.data["application_trends"].sort((a,b)=> a.count>b.count?-1:1)[0].year
        let summary ={definition:technology.definition,totalApplications: totalApplications ,topYear:topYear,topAssignee:toTitleCase(topAssignee),topCountry:topCountry}
        // console.log(summary)
        resolve(summary)
        
      })
    })
    
  }

  useEffect(()=>{
    if (props.technologies&&props.technologies.length>0){
      let tempTechnologySummary = props.technologies.map((technology)=>{
        return generateTechnologySummary(technology)
      })
      Promise.all(tempTechnologySummary)
      .then(summary=>{
        setTechnologySummary([...summary])
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  },[props.technologies])

  const sanitizeText = (str) => {
    if (typeof str === "string") {
      str = str.replace("_", " ");
      return str[0].toUpperCase() + str.slice(1, str.length).toLowerCase();
    } else {
      return str;
    }
  };

  const navigate = useNavigate();

  const handlePopoverClick = (e, item) => {
    setPopoverContent({ title: item[0], body: `${item[1]} related patents` });
    setPopoverTarget(e.target);
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <div className="reportCPC">
      {props.showHeading && (
        <div className="newTechnologyTableheading">
          {props.heading ? props.heading : "Patent Technologies"}
        </div>
      )}
      <Table className="mainTable">
        <thead>
          <tr>
            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                width: "35%",
                backgroundColor: "#020b26",
                color: "white",
                textAlign: "center !important"
              }}
            >
              Technology Name
            </th>

                <th className="text-center" style={{
                      fontWeight: "normal",
                      backgroundColor: "#020b26",
                      color: "white",
                    }}
                 >
                    Total Patents
                </th>

         
              <th
                className="text-center"
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#020b26",
                  color: "white",
                  textAlign: "end",
                  marginLeft: "-5%",
                }}
              >
                Most Active Year
              </th>

              <th
                className="text-center"
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#020b26",
                  color: "white",
                  marginRight: "4%"
                }}
              >
                Top Organization
              </th>

              <th  className="text-center"
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#020b26",
                  color: "white",
                  marginRight: "-8%",
                }}
              >
                Top Country
              </th>

          </tr>
        </thead>
        <tbody>
          {technologySummary ? (
            technologySummary&&technologySummary.map((item,index)=>
              <tr
                className="rowTable"
                key={index}
              >
                <td className="reportTableFirst" style={{width: "35%"}}>
                {item.definition}
                </td>
                <td className="reportTableSecond" style={{width: "20%"}}>
                {item.totalApplications}
                </td>
                <td className="reportTableThird" style={{width: "5%"}}>
                {item.topYear}
                </td>
                <td className="reportTableFourth" style={{width: "35%"}}>
                {item.topAssignee}
                </td>
                <td className="reportTableFifth" style={{width: "5%"}}>
                {item.topCountry}
                </td>
              </tr>
            )) : (
              <tr className="rowTable">
                <td className="reportTableFirst">
                  <Skeleton className="customSkeleton" width={300} height={20} count={5}/>
                </td>
                <td className="reportTableSecond">
                  <Skeleton className="reportTableSecondSkeleton" width={50} height={20} count={5}/>
                </td>
                <td className="reportTableThird">
                  <Skeleton className="customSkeleton" width={50} height={20} count={5}/>
                </td>
                <td className="reportTableFourth">
                  <Skeleton className="customSkeleton" width={50} height={20} count={5}/>
                </td>
                <td className="reportTableFifth">
                  <Skeleton className="customSkeleton" width={50} height={20} count={5}/>
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  );
};
