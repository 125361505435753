import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { moveToResultsPage, truncateText } from "../../actions/helper";
import { FaSearch } from "react-icons/fa";
import "./searchPageHeader.css";

export const SearchPageHeader = (props) => {
  const [query, setQuery] = useState(
    window.location.search.split("?q=").length > 1
      ? decodeURIComponent(window.location.search).split("?q=")[1]
      : localStorage.getItem("query")
  );
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filterOption, setFilterOption] = useState('applicationNumber');

  const cpcSuggestionsAPI =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/cpc";
  const cpcDefinitionAPI = "https://eaf3yrusqj.execute-api.us-east-1.amazonaws.com/production/cpc-definition";

  useEffect(() => {
    setQuery(
      window.location.search.split("?q=").length > 1
        ? decodeURIComponent(window.location.search).split("?q=")[1]
        : localStorage.getItem("query")
    );
    getCpcSuggestions(query);
    setSelectedSuggestion(null)
    // console.log("sd")
  }, [window.location.search,props.resetFilters]);

  const navigate = useNavigate();
  const redirect = (e) => {
    if (e == "Dataset") {
      moveToResultsPage(navigate, query);
    } else if (e == "Trends & Activity") {
      moveToResultsPage(navigate, query, "trends");
    } else if (e == "global") {
      moveToResultsPage(navigate, query, "global");
    } else if (e == "technology") {
      moveToResultsPage(navigate, query, "technology");
    }
  };

  const search = (e) => {
    if (e.key === "Enter") {
      localStorage.setItem("query", query);
      getCpcSuggestions(query);
      props.searchFunction(query);
      moveToResultsPage(navigate, query, "dataset");
      window.history.replaceState(null, "DYR:Results", "/results?q=" + query);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion((prevSelectedSuggestion) =>
      suggestion === prevSelectedSuggestion ? null : suggestion
    );
    props.searchFunction(
      query,
      suggestion === selectedSuggestion
        ? null
        : "(industry eq '" + suggestion + "')"
    );
    moveToResultsPage(navigate, query, "dataset");
  };

  const getCpcSuggestions = (query) => {
    setSuggestions([]);
    axios
      .get(cpcSuggestionsAPI, { params: { query: query, select: "industry" } })
      .then((response) => {
        setSuggestions(response.data.filter(item=>item!==null));
      });
  };

  return (
    <div>
      <hr className="mt-0" style={{background: "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
    height: "2px"}}/>
      <Row className="g-0">
      <Col xs="12">
        <div
          className="searchBox searchBoxNew"
          style={{
            marginTop: "10px",
            marginLeft: "20px",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="searchDiv"
            style={{ alignItems: "flex-start", justifyContent: "left" }}
          >
            <div className="brandName" style={{background: "linear-gradient(to right, rgb(2, 11, 38) 10%, rgb(175, 65, 240) 90%) text",
    color: "transparent"}}>Do Your Research</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                position: "relative", 
              }}
            >
              {/* Search input */}
              <input
                className="searchBar"
                style={{ borderRadius: "10px", marginTop: "10px",
                  marginTop: "10px",
                  background: "rgb(250, 241, 236)",
                  border: "1px solid rgb(250, 241, 236)"}}
                placeholder={props.placeholder ? props.placeholder : ""}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyUp={(e) => {search(e)}}
              />
             
              {/* <select
                className="filterDropdown"
                style={{
                  border: "1px solid #ededed",
                  marginTop: "10px",
                  marginRight: "10px", 
                  position: "absolute",
                  right: "3.5%",
                  padding: "9px",
    backgroundColor: "#ededed"
                }}
                value={filterOption}
                onChange={(e) => setFilterOption(e.target.value)}
              >
                <option value="applicationNumber">Application Number</option>
                <option value="query">Query</option>
                <option value="organization">Organization</option>
              </select> */}
              
              <FaSearch
                className="searchIcon"
                style={{ right: "10px" }}
                onClick={() => {
                  search({ key: "Enter" });
                }}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="7">
          {/* <div className="columnItems">
          <div
            className="suggestionsText"
            style={{
              fontSize: "0.7rem",
              marginLeft: "25px",
              marginTop: "13px",
            }}
          >
            Navigate:
          </div>
          {suggestions.length > 0 ? (
            <ul className="suggestions" style={{marginTop :"0px", marginLeft: "10px"}}>
              {suggestions.map((suggestion, index) => (
                <li
                  className={
                    suggestion === selectedSuggestion ? "selected" : ""
                  }
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {truncateText(suggestion, 50)}
                </li>
              ))}
            </ul>
          ) : (
            <ul className="suggestions">
              <Skeleton />
            </ul>
          )}
          </div> */}
        </Col>
        {/* <Col xs={{ span: 12, order: 2 }} md="5" style={{ display: "flex" }}>
  <div className="sectionContainer">
    <div
      className={`sectionButton ${
        (window.location.pathname.split("/").at(-1) == "technology") |
        (window.location.pathname.split("/").at(-1) == "results")
          ? "bold"
          : ""
      }`}
      onClick={() => redirect("technology")}
    >
      Technology
    </div>
    <div
      className={`sectionButton ${
        window.location.pathname.split("/").at(-1) == "company"
          ? "bold"
          : ""
      }`}
      onClick={() => redirect("company")}
    >
      Company
    </div>
    <div
      className={`sectionButton ${
        window.location.pathname.split("/").at(-1) == "trends"
          ? "bold"
          : ""
      }`}
      onClick={() => redirect("trends")}
    >
      Trends & Insights
    </div>
    <div
      className={`sectionButton ${
        window.location.pathname.split("/").at(-1) == "global"
          ? "bold"
          : ""
      }`}
      onClick={() => redirect("global")}
    >
      Global
    </div>
  </div>
</Col> */}

      </Row>
    </div>
  );
};
