import React, { useState } from "react";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";
import axios from "axios";
import { FaUser, FaUnlock, FaLock } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import "../../pages/signUp/signUp.css";
import {
  moveToResultsPage,
  moveToSignInPage,
  truncateText,
} from "../../actions/helper";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const SignupForm = () => {
  const [flag, setFlag] = useState(true);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [signupName, setSignupName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgName, setOrgName] = useState("self");
  const [type, setType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const navigate = useNavigate();

  const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";
  const registerURL = "https://62zki6r8ok.execute-api.us-east-1.amazonaws.com/production/register";
  const authenticationURL =
    "https://62zki6r8ok.execute-api.us-east-1.amazonaws.com/production/authenticate";

  const sendEmail = (userEmail, orgName) => {
    const recipients = {
      to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
    };
    const content = {
      subject: "(System Alert) DYR Full Access Requested",
      body: `${userEmail} from ${orgName} has requested full access to the dashboard`,
      html: "",
    };
    axios.post(emailURL, { recipients: recipients, content: content });
  };

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const handleSignupLinkClick = (e) => {
    e.preventDefault();
    handleToggle();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
  
    let payload = {
      email: signupEmail, // Use the new signup email variable
      password: signupPassword, // Use the new signup password variable
      name: signupName, // Use the new signup name variable
      employer: "self",
    };
  
    axios.post(registerURL, payload).then((res) => {
      if (res.data.status_code === 200) {
        setFlag(false);
        localStorage.setItem("user", signupName);
        localStorage.setItem("email", signupEmail);
        localStorage.setItem("token", res.data.msg);
        sendEmail(signupEmail, "self"); // Pass the signup email
        navigate("/for-you/state-of-the-art");
      } else {
        setErrorMessage(truncateText(res.data.msg, 50));
        sendEmail(signupEmail, "self"); // Pass the signup email
      }
      setStep(4);
    });
  };
  

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
  
    try {
      const response = await axios.get(authenticationURL, {
        params: { user_name: loginEmail, password: loginPassword }, // Use the new login variables
      });
  
      if (response.data.status_code === 200) {
        let decoded_token = jwtDecode(response.data.auth_token);
        setFlag(false);
        localStorage.setItem("user", decoded_token.user.name);
        localStorage.setItem("email", decoded_token.user.id);
        localStorage.setItem("token", response.data.auth_token);
        navigate("/for-you/state-of-the-art");
        sendEmail(loginEmail, decoded_token.user.employer); // Pass the login email
      } else if (response.data.status_code === 400) {
        setErrorMessage("No user account found with the provided email. Please sign up instead");
      } else if (response.data.status_code === 401) {
        setErrorMessage("Invalid credentials. Please check your email and password.");
      } else if (response.data.status_code === 403) {
        setErrorMessage("Access forbidden. You do not have permission to access.");
      } else {
        setErrorMessage(truncateText(response.data.msg, 100));
        sendEmail(loginEmail, ""); // Pass the login email
      }
    } catch (error) {
      console.error("Login failed", error);
      if (error.response) {
        console.log(error.response.data);
      } else if (error.request) {
        setErrorMessage("No response from the server. Please try again later.");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
      sendEmail(loginEmail, ""); // Pass the login email
    }
  };
  

  const handleVerificationSubmit = () => {
    setStep(2);
  };

  const handleSubmitCode = (e) => {
    e.preventDefault();
    setStep(3);
  };

  const handleVerified = (e) => {
    e.preventDefault();
    setStep(4);
  };

  const moveToSignIn = () => {
    navigate("/sign-in");
  };

  return (
    <div>
      <div className="wrapper">
        <div className="title-text">
          <div className={`title ${isLogin ? "login active" : "signup"}`}>
            {isLogin
              ? "Welcome back! Log in to your account."
              : "Activate your DYR account. Sign up now!"}
          </div>
        </div>
        <div className="form-container">
          <div className="slide-controls">
            <input
              type="radio"
              name="slide"
              id="login"
              checked={isLogin}
              onChange={handleToggle}
            />
            <input
              type="radio"
              name="slide"
              id="signup"
              checked={!isLogin}
              onChange={handleToggle}
            />
            <label
              htmlFor="login"
              className="slide login"
              onClick={handleToggle}
            >
              Login
            </label>
            <label
              htmlFor="signup"
              className="slide signup"
              onClick={handleToggle}
            >
              Signup
            </label>
            <div className="slider-tab"></div>
          </div>
          <div className="form-inner">
            {isLogin ? (
              <form action="#" className="login" onSubmit={handleLogin}>
                <div className="field">
                  <input
                    type="text"
                    value={loginEmail}
                    placeholder="Email Address"
                    onChange={(e) => setLoginEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="password"
                    value={loginPassword}
                    placeholder="Password"
                    onChange={(e) => setLoginPassword(e.target.value)}
                    required
                  />
                </div>
                {/* <div className="pass-link">
                  <a href="#">Forgot password?</a>
                </div> */}
                <div className="field btn">
                  <div className="btn-layer"></div>
                  <input type="submit" value="Login" />
                </div>
                {/* <div className="signup-link">
                    Not a member?{" "}
                    <a href="#" onClick={handleSignupLinkClick}>
                      Signup now
                    </a>
                  </div> */}
              </form>
            ) : (
              <form action="#" className="signup" onSubmit={handleSubmit}>
                <div className="field">
                  <input
                    type="text"
                    value={signupName}
                    placeholder="Enter your name"
                    onChange={(e) => setSignupName(e.target.value)}
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={signupEmail}
                    placeholder="Enter your academic/business email"
                    onChange={(e) => setSignupEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="password"
                    value={signupPassword}
                    placeholder="Enter password"
                    onChange={(e) =>setSignupPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="field">
                  <input
                    type="password"
                    value={confirmPassword}
                    placeholder="Confirm password"
                    onChange={(e) =>setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <p className="mt-4" style={{ color: "red" }}>
                  {errorMessage}
                </p>
                <div className="field btn">
                  <div className="btn-layer"></div>
                  <input type="submit" value="Signup" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      {/* <Row className="g-0">
        <Container className="d-flex justify-content-center signupContainer">
          {flag ? (
            <div style={{ width: "95%" }}>
              <div className="getAccessText">Sign Up</div>
              <Form
                style={{ width: "100%", marginTop: "40px" }}
                onSubmit={
                  isVerificationMode ? handleVerificationSubmit : handleSubmit
                }
              >
                <FormGroup
                  className="mt-4 d-flex align-items-center"
                  style={{
                    marginBottom: "100px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Label
                    for="name"
                    className="signupLabel"
                    style={{
                      marginRight: "0px",
                      fontWeight: "bold",
                      marginBottom: "5px",
                      width: "15%",
                    }}
                  >
                    <FaUser />
                    &nbsp;&nbsp;
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="signupLabel"
                    style={{
                      // backgroundColor: "#f5f5f5",
                      color: "#000",
                      marginBottom: "5px",
                      width: "70%",
                      border: "none",
                    }}
                    placeholder="Enter your name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup
                  className="mt-4 d-flex align-items-center"
                  style={{
                    marginBottom: "100px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Label
                    for="email"
                    className="signupLabel"
                    style={{
                      marginRight: "0px",
                      fontWeight: "bold",
                      marginBottom: "5px",
                      width: "15%",
                    }}
                  >
                    <MdMail />
                    &nbsp;&nbsp;
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    className="signupLabel"
                    style={{
                      // backgroundColor: "#f5f5f5",
                      color: "#000",
                      marginBottom: "5px",
                      width: "70%",
                      border: "none",
                    }}
                    placeholder="Enter your academic/business email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup
                  className="mt-4 d-flex align-items-center"
                  style={{
                    marginBottom: "100px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Label
                    for="name"
                    className="signupLabel"
                    style={{
                      marginRight: "0px",
                      fontWeight: "bold",
                      marginBottom: "5px",
                      width: "15%",
                    }}
                  >
                    <FaUnlock />
                    &nbsp;&nbsp;
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    className="signupLabel"
                    style={{
                      // backgroundColor: "#f5f5f5",
                      color: "#000",
                      marginBottom: "5px",
                      width: "70%",
                      border: "none",
                    }}
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup
                  className="mt-4 d-flex align-items-center"
                  style={{
                    marginBottom: "100px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Label
                    for="type"
                    className="signupLabel"
                    style={{
                      marginRight: "0px",
                      fontWeight: "bold",
                      marginBottom: "5px",
                      width: "15%",
                    }}
                  >
                    <FaLock />
                    &nbsp;&nbsp;
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="confirm-password"
                    className="signupLabel"
                    style={{
                      // backgroundColor: "#f5f5f5",
                      color: "#000",
                      marginBottom: "5px",
                      width: "70%",
                      border: "none",
                    }}
                    placeholder="Confirm password"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                </FormGroup>

                {isVerificationMode && (
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="verificationCode"
                      className="signupLabel"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      Verification Code
                    </Label>
                    <Input
                      type="text"
                      name="verificationCode"
                      id="verificationCode"
                      className="signupLabel"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter verification code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      required
                    />
                  </FormGroup>
                )}

                <p className="mt-4" style={{ color: "red" }}>
                  {errorMessage}
                </p>
                <Button type="submit" className="signupButton">
                  {isVerificationMode ? "Verify" : "Submit"}
                </Button>
                <br />
              </Form>
            </div>
          ) : (
            <div className="afterSignUp">
              <p className="mt-4">
                We have received your request, we will get back to you with
                updates.{" "}
              </p>
              <Button
                className="signupDYR"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  moveToResultsPage(navigate, "Cold nuclear fusion");
                }}
              >
                Do Your Research
              </Button>
            </div>
          )}
        </Container>
        </Row> */}
      {/* <Container className="d-flex justify-content-center signupContainer">
 {step ===1 && (
              <div style={{ width: "95%" }}>
                <div className="getAccessText">Get Access.</div>
                <Form
                  style={{ width: "100%", marginTop: "50px" }}
                  onSubmit={handleSubmit}
                >
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUser />
                      &nbsp;&nbsp;Name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="email"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <MdMail />
                      &nbsp;&nbsp;Email
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter your academic/business email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="name"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaUnlock />
                      &nbsp;&nbsp;Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Enter password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for="type"
                      style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                      }}
                    >
                      <FaLock />
                      &nbsp;&nbsp;Confirm Password
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="confirm-password"
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "70%",
                      }}
                      placeholder="Confirm password"
                      onChange={(e) => setType(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <p className="mt-4" style={{color:'red'}}>{errorMessage}</p>
          <Button type="submit" className="signupButton" onClick={()=>{()=>{handleSubmit()}}}>
            Submit
          </Button>
        </Form>
      </div>
                  )}

                  {step === 2 && (
            <Form style={{ width: "90%", marginTop: "50px" }} onSubmit={handleSubmitCode}>
              <FormGroup className="mt-4 d-flex align-items-center" style={{ marginBottom: "100px" }}>
                <Label for="verificationCode" style={{ marginRight: "0px", fontWeight: "bold", marginBottom: "5px", width: "50%" }}>
                  Enter email verification code
                </Label>
                <Input
                  type="text"
                  name="verificationCode"
                  id="verificationCode"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                  }}
                  placeholder="Enter email verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                />
              </FormGroup>
              <p className="mt-4" style={{color:'red'}}>{errorMessage}</p>
          <Button type="submit" className="signupButton">
            Submit
          </Button>
        </Form>
            )}

            {step === 3 && (
                <div className="afterSignUp">
                <p className="mt-4">
                  Successfully signed up.{" "}
                </p>
                <Button
                  className="signinDYR"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    moveToResultsPage(navigate, "Cold nuclear fusion");
                  }}
                >
                  Do Your Research
                </Button>
              </div>
            )}   
          </Container> */}
    </div>
  );
};

export default SignupForm;
