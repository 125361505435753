import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "./countryPage.css";
import { Tracking } from "../../components/tracking/tracking";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import SimpleMap from "../../components/WorldMap/WorldMap";
import { useLocation } from "react-router-dom";

const CountryPage = () => {
    const location = useLocation()

    const [cpcs, setCPCs] = useState((location.state)?location.state.cpcs:["G06F 16/00"])
    const [technologyOverview, setTechnologyOverview] = useState();
    const [patentsInTechnology, setPatentsInTechnology] = useState();
    const [topYear, setTopYear] = useState();
    const [topYearCount, setTopYearCount] = useState();
    const [grantRate,setGrantRate] = useState();

    const cpcAPI = "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/cpc";
    const cpcDefinitionAPI = "https://eaf3yrusqj.execute-api.us-east-1.amazonaws.com/production/cpc-definition";
    const analyticsAPI = "https://eaf3yrusqj.execute-api.us-east-1.amazonaws.com/production/technology-analysis";

    useEffect(()=>{
      axios.post(cpcDefinitionAPI,[])
      getTechnologyOverview(cpcs[0])
    },[])

    const getTechnologyOverview = (cpc) => {
        axios.get(analyticsAPI, { headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},
        params: { cpc: cpc } })
        .then((response) => {
          // console.log(response.data)
          setTechnologyOverview(response.data);
          setPatentsInTechnology(response.data["total_applications"]);
          let tempTopYear = new Date().getFullYear();
          let tempTopYearCount = 0;
          response.data["application_trends"].forEach((item) => {
            if (item.count > tempTopYearCount) {
              tempTopYear = item.year;
              tempTopYearCount = item.count;
            }
          });
          setTopYear(tempTopYear);
          setTopYearCount(tempTopYearCount);
          response.data.status.forEach((item) => {
            if (Object.keys(item)[0] === "Active") {
              setGrantRate(
                (
                  (item["Active"] * 100) /
                  response.data["total_applications"]
                ).toFixed(1)
              );
            }
          });
        });
      };

  return (
    <div>
        <Tracking/>
        <Row className="g-0">
            <Col xs="12">
              <PersonalizedHeader/>
            </Col>
        </Row>

        <Row className="g-0" style={{ marginTop: "10px" }}>
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.875rem",
              backgroundColor: "#020b26",
              color: "white",
              height: "40px",
            }}
          >
            Global Landscape
          </span>
          <hr className="thin-horizontal-line" />
        </Col>
      </Row>


      <Row className="g-0">
        <Col
          xs="12"
          md="6"
          style={{ borderRight: "0.5px solid black", height: "750px" }}
        >
          <div className="landscapeTag">Global Landscape: {(technologyOverview)?technologyOverview.country_analysis.top_countries[0].country:<Skeleton/>}</div>
          <div className="para" style={{ marginLeft: "25px" }}>
            {(technologyOverview)?
            <p>{technologyOverview.country_analysis.top_countries[0].country} is leading the research under technology, followed by {technologyOverview.country_analysis.top_countries[1].country}, {technologyOverview.country_analysis.top_countries[2].country}, and {technologyOverview.country_analysis.top_countries[3].country}.</p>
            :
            <Skeleton count={2}/>
            }
          </div>
          <div
            style={{
              marginLeft: "25px",
              fontSize: "0.875rem",
              marginTop: "20px",
            }}
          >
            {(topYear)?
            <p>Recently in {topYear}, {topYearCount} no. of patents were filed.</p>
            :
            <Skeleton/>
            }
          </div>
          {(technologyOverview)?
          <div className="countryContainer">
            {technologyOverview.country_analysis.top_countries.map((item,index)=>
            <div className="countryBox" key={index}>
              <p style={{marginBottom: '10px'}}>{item.country}</p>
              <span style={{color: "#6b859e"}}>{item.count} applications</span>
            </div>
            )}
          </div>
          :
          <Skeleton/>
          }
          <div style={{ marginLeft: "50px" }}>
            <SimpleMap
              // data={(technologyOverview)?technologyOverview.country_analysis.top_countries.map((item)=>{return {"country":item.country,"value":item.count}}):[]}
              color="blue"
              backgroundColor="white"
              borderColor="grey"
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="globalTechText">
            Global activity under the technology.
          </div>
          <div className="box-container" style={{alignItems: "center", justifyContent: "center"}}>
            <div className="box">
              <div className="widget-block">
                {typeof technologyOverview !== "undefined" ? (
                  <div className="value">
                    {technologyOverview.country_analysis.total_countries}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.7rem" }}>
                  Countries Working
                </p>
              </div>
            </div>
            <div className="box">
              <div className="widget-block">
                {typeof technologyOverview !== "undefined" ? (
                  <div className="value">
                    {
                      technologyOverview.assignee_analysis.total_assignees
                    }
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.7rem" }}>
                  Organizations Working
                </p>
              </div>
            </div>
            <div className="box">
              <div className="widget-block">
                {typeof technologyOverview !== "undefined" ? (
                  <div className="value">
                    {/* {
                      technologyOverview.country_analysis.top_countries[0]
                        .country
                    } */}
                    300
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.7rem" }}>
                  Inventors
                </p>
              </div>
            </div>
          </div>
          <hr className="mt-4"/>
          <div className="globalTechText">
            Country specific activity under technology
          </div>
          <div
            className="heading"
            style={{
              backgroundColor: "#020b26",
              padding: "10px",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "127px",
              marginTop: "20px",
              width: "72%"
            }}
          >
            <span style={{ marginLeft: "10px" }}>United States</span>
          </div>
          <div className="box-container2" style={{ marginTop: "0px", marginLeft: "130px"}}>
              <div
                className="box2"
                style={{ borderRight: "2px solid #020b26", width: "calc(33.33% - 40px)", borderBottom: "2px solid #020b26"  }}
              >
                <div className="widget-block">
                  {typeof topYear !== "undefined" ? (
                    <div className="value2">{topYear}</div>
                  ) : (
                    <Skeleton />
                  )}
                  <p
                    className="body-3"
                    style={{ fontSize: "0.7rem", color: "#6b859e" }}
                  >
                    High Research Activity
                  </p>
                </div>
              </div>
              <div
                className="box2"
                style={{ borderRight: "2px solid #020b26",width: "calc(33.33% - 40px)", borderBottom: "2px solid #020b26"   }}
              >
                <div className="widget-block">
                  {typeof technologyOverview !== "undefined" ? (
                    <div className="value2">{topYearCount}</div>
                  ) : (
                    <Skeleton />
                  )}
                  <p
                    className="body-3"
                    style={{ fontSize: "0.7rem", color: "#6b859e" }}
                  >
                    Patents Filed in {topYear}{" "}
                  </p>
                </div>
              </div>
              <div className="box2" style={{width: "calc(33.33% - 40px)", borderBottom: "2px solid #020b26"   }}>
                {typeof grantRate != "undefined" ? (
                  <div className="value">{grantRate}%</div>
                ) : (
                  <Skeleton />
                )}
                <p
                  className="body-3"
                  style={{ fontSize: "0.7rem", color: "#6b859e" }}
                >
                  Grant Rate
                </p>
              </div>
            </div>
            <div className="box-container2" style={{ marginTop: "0px", marginLeft: "130px" }}>
              <div
                className="box2"
                style={{ borderRight: "2px solid #020b26",width: "calc(33.33% - 40px)" }}
              >
                <div className="widget-block">
                  {typeof topYear !== "undefined" ? (
                    <div className="value2">{topYear}</div>
                  ) : (
                    <Skeleton />
                  )}
                  <p
                    className="body-3"
                    style={{ fontSize: "0.7rem", color: "#6b859e" }}
                  >
                    High Research Activity
                  </p>
                </div>
              </div>
              <div
                className="box2"
                style={{ borderRight: "2px solid #020b26",width: "calc(33.33% - 40px)" }}
              >
                <div className="widget-block">
                  {typeof technologyOverview !== "undefined" ? (
                    <div className="value2">{topYearCount}</div>
                  ) : (
                    <Skeleton />
                  )}
                  <p
                    className="body-3"
                    style={{ fontSize: "0.7rem", color: "#6b859e" }}
                  >
                    Patents Filed in {topYear}{" "}
                  </p>
                </div>
              </div>
              <div className="box2" style={{width: "calc(33.33% - 40px)"}}>
                {typeof grantRate != "undefined" ? (
                  <div className="value">{grantRate}%</div>
                ) : (
                  <Skeleton />
                )}
                <p
                  className="body-3"
                  style={{ fontSize: "0.7rem", color: "#6b859e" }}
                >
                  Grant Rate
                </p>
              </div>
            </div>
        </Col>
      </Row>
    </div>
  )
}

export default CountryPage